import { Cookies } from 'react-cookie';
import { Services } from './Services.js';
// Object.defineProperty(exports, "__esModule", { value: true });

var services = new Services();

export function Auth() {
    const cookie = new Cookies();
    var _this = this;

	this.login = (username, password, grecaptcha) => {
        return new Promise(async (resolve, reject) => {
          await services.login(username, password, grecaptcha)
                        .then((response) =>{
                            _this.setAuthorizationCookie(response.entity.access_token);
                            cookie.set("username", username, {path: "/"});
                            resolve(response);
                        
                            //window.location.reload();
                            //console.log("xx: ", response);
                        })
                        .catch((error) => {
                           reject(error);
                        })
       })
    }


    this.logout = () => {
        return new Promise(async (resolve, reject) => {
           return await services.logout()
                           .then(() => {
                               _this.removeAuthorizationCookie();
                               resolve();
                           })
                           .catch(() => {
                               _this.removeAuthorizationCookie();
                               resolve();
                           })
        })
    }

    this.isAuthenticated = () => {
        return _this.hasAuthorizationCookie();
    }

    this.hasAuthorizationCookie = () => {
        let hasToken = cookie.get("access_token");
        return !!hasToken;
    }
    
    this.setAuthorizationCookie = (val) => {
        _this.removeAuthorizationCookie();
        cookie.set("access_token", val, {path: "/"});
    }

    this.removeAuthorizationCookie = () => {
        cookie.remove("access_token", {path: "/"});
    }

    this.getLoginToken = () =>{
        return cookie.get("access_token"); 
    }
};

// if (typeof module === "object" && module.exports) {
//     module['exports'] = Auths;
// };

// if (typeof Module === 'undefined')
//     Module = {};
// return Auths(Module);
// export default Auths;

