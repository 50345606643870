/* eslint-disable */
// const test = "https://guanyin-p.geovisiongroup.com";
// const test = "https://api-dev.heybringo.com";
// const prod = "https://api.heybringo.com"
// //const env = prod;
// const env = test;
// export const BASE_URL =`${env}/`;
/* eslint-disable */

var env_test = {
    AUTH_ADDRESS: "https://t.api.geovisiondispatch.geovisiongroup.com/",
    WMS_SERVER_URL: "https://geoserver-p.geovisiongroup.com/wms",
    guanyin_address: "https://t.api.geovisiondispatch.geovisiongroup.com/",
};

var env_prod = {
    AUTH_ADDRESS: "https://auth.geovisiondispatch.com/service/",    
    WMS_SERVER_URL: "http://geoserver.geovisiongroup.com/wms",
    guanyin_address: "https://api.geovisiondispatch.com/service/",
};


//var env = env_test;
var env = env_prod;
//export const BASE_URL =`${env_prod.guanyin_address}`;
export const BASE_URL = env_prod;