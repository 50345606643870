import React, { useState, useEffect, useRef } from 'react'
import './style/Login.css'
import Recaptcha from "react-google-recaptcha"
import { Auth } from "../api/Auth";

const Login = () => {
    const [username, setUsername] = new useState();
    const [password, setPassword] = new useState();
    const [error, setError] = new useState();
    const [captchaToken, setRecaptchaToken] = useState(false);
    const ref = new useRef(0);
    var auth = new Auth();

    const verifyCaptcha = (key) => {
        setRecaptchaToken(key);
    };

    const handleChange = (event) => {
        if (event.target.value == "") setError("Input required");
        if (event.target.name == "username") setUsername(event.target.value);
        if (event.target.name == "password") setPassword(event.target.value);

    };

    const handleLogin = () => {
        if (captchaToken) {
            auth.login(username, password, captchaToken)
                .then((res) => window.location.reload())
                .catch((err) => {
                    // error messages checking 
                    switch (err.code) {
                        case "W0007":
                            alert("şifre yanlış")
                            break;
                        default:
                            alert("recaptch işaretleyiniz")
                            break;
                    }
                })

        } else {
            alert("recaptch işaretleyiniz")
        }
    }

    // useEffect(() => {
    //     if(auth.isAuthenticated()) window.location.reload()
    // }, [auth.isAuthenticated()])

    // useEffect(() => {
    //     console.log(captchaToken)
    // }, [captchaToken])

    return (
        <div className="container login_container">
            <div className="row">
                <div className="col-sm-9 col-md-7 col-lg-5 mx-auto">
                    <div className="card border-0 shadow rounded-3 my-5">
                        <div className="card-body p-4 p-sm-5">
                            <h5 className="card-title text-center mb-5 fw-light fs-5">Canlı Takip</h5>

                            <div className="form-floating mb-3">
                                <input type="text" class="form-control" id="floatingInput" placeholder="kullanıcı adı" name="username" onChange={handleChange} />
                                <label for="floatingInput">Kullanıcı Adı</label>
                            </div>
                            <div className="form-floating mb-3">
                                <input type="password" class="form-control" id="floatingPassword" placeholder="şifre" name="password" onChange={handleChange} />
                                <label for="floatingPassword">Şifre</label>
                            </div>

                            {/* <div class="form-check mb-3">
                                    <input class="form-check-input" type="checkbox" value="" id="rememberPasswordCheck" />
                                    <label class ="form-check-label" for="rememberPasswordCheck">
                                    Remember password
                                    </label>
                                </div> */}
                            <Recaptcha
                                ref={ref}
                                sitekey="6LdwpqkUAAAAAFippqW9n2vMwQLBFPQghHCWar4O"
                                render="explicit"
                                onChange={verifyCaptcha}
                            ></Recaptcha>
                            <div className="d-grid">
                                <button
                                    className="btn btn-primary btn-login text-uppercase fw-bold"
                                    type="submit"
                                    onClick={(event) => handleLogin(event)}
                                >Giriş
                                    Yap</button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login
