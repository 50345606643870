import React, {useState, useEffect} from 'react'
import { Cookies } from 'react-cookie';
import './style/Header.css'
import { Auth } from "../api/Auth";

const Header = (props) => {
    const { setBodyRedirect } = props;
    var auth = new Auth();
    const cookie = new Cookies();



    return (
        <>
            <div className="headerContent">
                <button type="button" className="btn btn-light headerBtn" onClick={() => setBodyRedirect("table")}> Liste </button>
                <button type="button" className="btn btn-light headerBtn" onClick={() => setBodyRedirect("map")}> Harita </button>
                <button type="button" style={{"width": "40px", "position": "absolute", "right": "10px"}} className="btn btn-danger headerBtn" onClick={() => auth.logout().then((res) => window.location.reload())}> <i class="fa fa-sign-out"></i> </button>
            </div>
        </>
    )
}

export default Header
