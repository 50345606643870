import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
// import { ProductService } from '../service/ProductService';
import { Rating } from 'primereact/rating';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Services } from '../api/Services.js';

import data from "./mockdata.js";
import './style/DataTable.css';
import "primeicons/primeicons.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.css";
import truck from "./img/truck.svg"

var services = new Services();

const Table = (props) => {
    //Parent Componenet (Body.js) = props
    const { vehicle, setVehicle, warehouse, setWarehouse, pageLoad, setPageLoad } = props;

    const [products, setProducts] = useState(data);
    const [expandedRows, setExpandedRows] = useState(null);
    const toast = useRef(null);
    const isMounted = useRef(false);
    //const [warehouse, setWarehouse] = useState();
    //const [vehicle, setVehicle] = useState()
    //const [pageLoad, setPageLoad] = useState(false)





    /**====================>>>>>>>>>>>>>>>>>>>>>>>> Color*/

    // Generates random colors
    const generateColor = (rowData) => {
        return '#' + Math.random().toString(16).substr(-6);
        // let color = '#' + Math.random().toString(16).substr(-6);
        // rowData["color"] = color;
    }

    /**====================>>>>>>>>>>>>>>>>>>>>>>>> Color*/






    useEffect(() => {
        if (isMounted.current) {
            const summary = expandedRows !== null ? 'All Rows Expanded' : 'All Rows Collapsed';
            toast.current.show({ severity: 'success', summary: `${summary}`, life: 3000 });
        }
    }, [expandedRows]);

    useEffect(() => {
        !warehouse && services.getWarehouse().then(res => setWarehouse(res.entity))
    }, [])

    // useEffect(() => {
    //     console.log("aa : ", pageLoad)
    //     console.log("jjjj : ", vehicle)
    // }, [pageLoad])

    useEffect(() => {
        //warehouse && services.getVehicles(JSON.stringify(warehouse.map(d => d.oid)))
        //warehouse && services.getVehicles(JSON.stringify([].concat(warehouse.map(d => d.oid))))
        !vehicle && warehouse && services.getVehicles(JSON.stringify(warehouse.map(d => d.oid))).then(res => setVehicle(res.entity))

    }, [warehouse])

    useEffect(() => {
        //console.log("vehicle: ", vehicle && vehicle.sessionVehicle);
        if (vehicle) {
            for (let i = 0; i < vehicle.sessionVehicle.length; i++) {
                if (vehicle.sessionVehicle[i].color == null) {
                    vehicle.sessionVehicle[i].color = generateColor()
                }
            }
        }

    }, [vehicle])


    // useEffect(() => {
    //     console.log("ABC :", vehicle)
    // }, [vehicle])

    // useEffect(() => {
    //     isMounted.current = true;
    //     productService.getProductsWithOrdersSmall().then(data => setProducts(data));
    // }, []); // eslint-disable-line react-hooks/exhaustive-deps

    // const onRowExpand = (event) => {
    //     toast.current.show({severity: 'info', summary: 'Product Expanded', detail: event.data.name, life: 3000});
    // }

    // const onRowCollapse = (event) => {
    //     toast.current.show({severity: 'success', summary: 'Product Collapsed', detail: event.data.name, life: 3000});
    // }

    // const expandAll = () => {
    //     let _expandedRows = {};
    //     products.forEach(p => _expandedRows[`${p.id}`] = true);

    //     setExpandedRows(_expandedRows);
    // }

    // const collapseAll = () => {
    //     setExpandedRows(null);
    // }

    const formatCurrency = (value) => {
        return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    }

    const amountBodyTemplate = (rowData) => {
        return formatCurrency(rowData.amount);
    }

    const statusOrderBodyTemplate = (rowData) => {
        return <span className={`order-badge order-${rowData.status.toLowerCase()}`}>{rowData.status}</span>;
    }

    const deliveryStatusBodyTemplate = (rowData) => {
        //return <span className="ds-green"></span>;
        //return <span>{rowData && rowData.outletVisitStatus}</span>;
        //delivery status symbol,  colors: green, red, yellow
        //delivered = green
        //not delivered = red
        //null = yellow 
        if (rowData.outletVisitStatus == "VISIT_COMPLETED") {
            return <span className="ds-green"></span>;
        } else if (rowData.outletVisitStatus == "VISIT_FAILED") {
            return <span className="ds-red"></span>;
        } else {
            return <span className="ds-yellow"></span>;
        }
    }


    const searchBodyTemplate = () => {
        return <Button icon="pi pi-search" />;
    }

    const imageBodyTemplate = (rowData) => {
        return <img src={`showcase/demo/images/product/${rowData.image}`} onError={(e) => e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={rowData.image} className="product-image" />;
    }

    const percentBodyTemplate = (rowData) => {
        // return formatCurrency(rowData.price);

        let vehicleOrdersCount, deliveredOrders, ratio
        if (rowData.completedOrderCount != 0) {
            //  vehicleSolidity = (Number(dataItem.size1) * 100 / Number(dataItem.vehicleSize1));
            //  remainingVehicleSolidity = ((Number(dataItem.size1) - Number(dataItem.deliveredSize1)) * 100) / Number(dataItem.vehicleSize1);
            
            
            // vehicleOrdersCount = rowData.orderCount;
            // deliveredOrders = rowData.completedOrderCount;
            // ratio = ((vehicleOrdersCount - deliveredOrders) * 100 / vehicleOrdersCount).toFixed(1);

            var completedOutletCount = rowData.vehicleOutlets.filter(function (el) { return el.outletVisitStatus; }).length;
            var totalOutletCount = rowData.vehicleOutlets.length;
            // var ratio = (totalOutletCount * 100 / completedOutletCount).toFixed(1);
            ratio = ((totalOutletCount - completedOutletCount) * 100 / totalOutletCount).toFixed(1);
           
        } else ratio = 100;

        //return (<div class="progress" style={{ "height": "28px", "width": "70px", "position": "relative" }}>
        return (<div class="progress" style={{ "height": "22px", "width": "56px", "position": "relative" }}>
            <div class={`progress-bar ${ratio < 51 ? 'dl-success' : ratio > 50 && ratio < 85 ? "dl-warning" : "dl-danger"}`} role="progressbar" style={{ "width": `${ratio}%` }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                <span className="ratioSpan" style={{ "color": `${ratio < 51 ? 'black' : ratio > 50 && ratio < 85 ? "black" : "white"}` }}>
                    {ratio}%
                </span>
            </div>

        </div>)

    }

    const ratingBodyTemplate = (rowData) => {
        return <Rating value={rowData.rating} readOnly cancel={false} />;
    }

    const statusBodyTemplate = (rowData) => {
        return <span className={`product-badge status-${rowData.inventoryStatus.toLowerCase()}`}>{rowData.inventoryStatus}</span>;
    }

    const numberOfCustomersBodyTemplate = (rowData) => {
        return <span className="ms">{rowData.outletCount}</span>;
    }

    const deliveredBodyTemplate = (rowData) => {
        return <span className="ms">{rowData.visitedOutletCount}</span>;
    }

    const undelivered = (rowData) => {
        return <span className="ms">{rowData.failedVisitOrderCount}</span>;
    }

    const toBeVisited = (rowData) => {
        return <span className="ms">{rowData.outletCount - (rowData.visitedOutletCount + rowData.failedVisitOrderCount)}</span>;
    }

    const user = (rowData) => {
        return (
            <div className="truckContent">
                <div className="truck" style={{ "background": rowData.color }}></div>
                <span className="truckText"> {rowData.username} </span>
            </div>
        )
    }

    const convertSecondsToHourFormat = (seconds, showSecond) => {
        seconds = seconds || 0;
        var mins = Math.floor(seconds / 60);
        var s = Math.round(seconds - (mins * 60));
        var minutes = Math.floor(mins) % 60;
        var hours = Math.floor(mins / 60);
        var h = hours < 10 ? "0" + hours : hours;
        var m = minutes < 10 ? "0" + minutes : minutes;
        return h + ":" + m + (showSecond ? ":" + s : "");
    }


    const deliveryTimeBodyTemplate = (rowData) => {
        return <span>{rowData.actualizationStartTime ? convertSecondsToHourFormat((rowData.actualizationStartTime + (new Date().getTimezoneOffset() * -1 * 60)), false) : "-"}</span>;
    }



    // const header = (
    //     <div className="table-header-container">
    //         <Button icon="pi pi-plus" label="Expand All" onClick={expandAll} className="p-mr-2" />
    //         <Button icon="pi pi-minus" label="Collapse All" onClick={collapseAll} />
    //     </div>
    // );


    /**calculation of total values */
    var aa = 0;
    var bb = 0;
    var cc = 0;
    vehicle && vehicle.sessionVehicle.map((d) => {
        aa += d.orderCount
        bb += d.visitedOutletCount
        cc += d.failedVisitOrderCount
    })

    //var dd = (bb + cc) / aa;
    //((vehicleOrdersCount - deliveredOrders) * 100 / vehicleOrdersCount).toFixed(1);
    // visit status, percentage of total value 
    var dd = 100 - ((bb + cc) * 100) / aa;
    var ee = dd.toFixed(2);

    const rowExpansionTemplate = (data) => {
        // sort by delivery time
        data.vehicleOutlets.sort((a, b) => parseInt(deliveryTimeBodyTemplate(a).props.children) - parseInt(deliveryTimeBodyTemplate(b).props.children))
        return (
            <div className="orders-subtable">
                {/* <DataTable value={sortedData} responsiveLayout="scroll"> */}
                <DataTable value={data.vehicleOutlets} responsiveLayout="scroll">
                    <Column field="oid" header="Teslim Durumu" body={deliveryStatusBodyTemplate}></Column>
                    <Column field="outlet.outletCode" header="Nokta Kodu/Sipariş"></Column>
                    <Column field="date" header="Teslim Zamanı" body={deliveryTimeBodyTemplate}></Column>
                    <Column field="outlet.signName" header="Nokta Adı"></Column>
                    <Column field="outlet.address" header="Adres"></Column>

                    {/* <Column field="amount" header="Amount" body={amountBodyTemplate} ></Column>
                    <Column field="status" header="Status" body={statusOrderBodyTemplate} ></Column>
                    <Column field="customer" header="Customer"></Column>
                    <Column field="customer" header="Customer"></Column>
                    <Column field="customer" header="Customer"></Column>
                    <Column field="customer" header="Customer"></Column>
                    <Column field="customer" header="Customer"></Column>
                    <Column field="customer" header="Customer"></Column> */}
                </DataTable>
            </div>
        );
    }


    return (
        <div className="datatable-rowexpansion-demo">
            {/* <Toast ref={toast} /> */}
            <div className="headerSpace"></div>

            <div className="card">
                <DataTable value={vehicle && vehicle.sessionVehicle}
                    expandedRows={expandedRows}
                    onRowToggle={(e) => setExpandedRows(e.data)}
                    responsiveLayout="scroll"
                    rowExpansionTemplate={rowExpansionTemplate}
                    dataKey="oid"
                // scrollable 
                // scrollHeight={"333px"}
                >
                    <Column expander />
                    <Column field="vehicleName" header="Kullanıcı" body={user} />
                    <Column field="vehicleName" header="Ziyaret Durumu" body={percentBodyTemplate} />
                    <Column field="vehicleName" header="Ziyaret Sayısı" body={numberOfCustomersBodyTemplate} />
                    <Column field="vehicleName" header="Teslim Edilen" body={deliveredBodyTemplate} />
                    <Column field="vehicleName" header="T.Edilemeyen" body={undelivered} />
                    <Column field="vehicleName" header="Bek.Ziyaret" body={toBeVisited} />
                </DataTable>


            </div>

            {/* creating spaces from fixed footer */}
            {/* <br />
            <br />
            <br /> */}
            {/* 
            <div className="footerFixed">
                <div className="zs vehicleTotal">
                    <div className="truck" style={{ "background": "white", "width": "45px !important" }}></div>
                    <span className="vehicleTotalText"> {vehicle && vehicle.sessionVehicle.length}</span>
                </div>

                <div className="zs">

                    <div class="progress" style={{ "height": "28px", "width": "70px", "position": "relative", "marginLeft": "132px" }}>
                        <div class={`progress-bar ${ee < 51 ? 'dl-success' : ee > 50 && ee < 85 ? "dl-warning" : "dl-danger"}`} role="progressbar" style={{ "width": `${ee}%` }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                            <span className="ratioSpan" style={{ "color": `${ee < 51 ? 'black' : ee > 50 && ee < 85 ? "black" : "white"}` }}>
                                {ee}%
                            </span>
                        </div>

                    </div>
                </div>

                <div className="totalOther">
                    <div className="zs">
                        {aa}
                    </div>
                    <div className="zs">
                        {bb}
                    </div>
                    <div className="zs">
                        {cc}
                    </div>
                    <div className="zs">
                        {aa - (bb + cc)}
                    </div>
                </div>
            </div> */}


            {/* 
               Fixed Footer
               Sum of all values
            */}
            <div className="footerFixed">
                <div className="zs vehicleTotal">
                    <div className="truck" style={{ "background": "white", "width": "45px !important" }}></div>
                    <span className="vehicleTotalText" style={{ "font-size": "13px", "margin-top": "1px", "font-weight": "700" }}> {vehicle && vehicle.sessionVehicle.length}</span>
                </div>

                <div className="zs">

                    <div class="progress" style={{ "height": "22px", "width": "56px", "position": "relative", "marginLeft": "132px" }}>
                        <div class={`progress-bar ${ee < 51 ? 'dl-success' : ee > 50 && ee < 85 ? "dl-warning" : "dl-danger"}`} role="progressbar" style={{ "width": `${ee}%` }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                            <span className="ratioSpan" style={{ "color": `${ee < 51 ? 'black' : ee > 50 && ee < 85 ? "black" : "white"}` }}>
                                {ee}%
                            </span>
                        </div>

                    </div>
                </div>

                <div className="totalOther">
                    <div className="zs fontZs" style={{ "background": "white", "border-bottom": "3px solid #3c70fa", "border-radius": "unset", "color": "black" }}>
                        {aa}
                    </div>
                    <div className="zs fontZs" style={{ "background": "white", "border-bottom": "3px solid rgb(19, 223, 12)", "border-radius": "unset", "color": "black" }}>
                        {bb}
                    </div>
                    <div className="zs fontZs" style={{ "background": "white", "border-bottom": "3px solid red", "border-radius": "unset", "color": "black" }}>
                        {cc}
                    </div>
                    <div className="zs fontZs" style={{ "background": "white", "border-bottom": "3px solid orange", "border-radius": "unset", "color": "black" }}>
                        {aa - (bb + cc)}
                    </div>
                </div>
            </div>



            {/* 
            <div className="footerFixed1">

                <table border="1" style={{ "border": "none", "width": "100%", "marginTop": "9px" }}>
                    <tr>
                        <td style={{ "position": "absolute", "top": "0px" }}>
                            <div className="zs vehicleTotal">
                                <div className="truck" style={{ "background": "white", "width": "45px !important" }}></div>
                                <span className="vehicleTotalText" style={{ "font-size": "13px", "margin-top": "1px", "font-weight": "700" }}> {vehicle && vehicle.sessionVehicle.length}</span>
                            </div>
                        </td>
                        <td style={{ "paddingLeft": "36px", "display": "flex", "justify-content": "end", "marginLeft": "23px" }}>
                            <div class="progress" style={{ "height": "22px", "width": "56px", "position": "relative" }}>
                                <div class={`progress-bar ${ee < 51 ? 'dl-success' : ee > 50 && ee < 85 ? "dl-warning" : "dl-danger"}`} role="progressbar" style={{ "width": `${ee}%` }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                                    <span className="ratioSpan" style={{ "color": `${ee < 51 ? 'black' : ee > 50 && ee < 85 ? "black" : "white"}` }}>
                                        {ee}%
                                    </span>
                                </div>
                            </div>
                        </td>
                        <td style={{ "width": "45px" }}>{aa}</td>
                        <td style={{ "position": "relative", "right": "6px" }}>{bb}</td>
                        <td style={{ "position": "relative", "left": "6px" }} >{cc}</td>
                        <td style={{ "position": "relative", "left": "5px" }}>{aa - (bb + cc)}</td>

                    </tr>
                </table>
            </div> */}

        </div>
    );
}

export default Table
