import React, {useState, useEffect} from 'react'
import Table from './Table'
import Map from './Map'


const Body = (props) => {
    const { redirect } = props;
    const [vehicle, setVehicle] = useState(); //Vehicles data
    const [warehouse, setWarehouse] = useState(); //Warehouse data
    const [pageLoad, setPageLoad] = useState()

    //page redirect (header fixed)
    return (
        <> 
            {redirect == "table" ? <Table pageLoad={pageLoad} setPageLoad={setPageLoad} warehouse={warehouse} setWarehouse={setWarehouse} vehicle={vehicle} setVehicle={setVehicle} /> : <Map  vehicle={vehicle} setPageLoad={setPageLoad} />}
        </>
    )
}

export default Body
