import React, { useEffect, useState } from 'react'
import { MapContainer, TileLayer, Circle, Marker, Popup, useMap, Polyline, CircleMarker } from 'react-leaflet'
import L from 'leaflet';
import './style/Map.css'
import truckIcon from "../screen/img/truck.svg"
import { Services } from '../api/Services.js';
import { renderToStaticMarkup } from "react-dom/server";

var services = new Services();

const Map = (props) => {
    const { vehicle, setPageLoad } = props;

    setPageLoad(false)
    const [lastPosition, setLastposition] = useState();
    // console.log("map vehicle : ", vehicle.sessionVehicle);
    // console.log("xxx : ", vehicle.sessionVehicle[0].routeCoordinates)


    // useEffect(() => {
    //    console.log("Last pos: ", lastPosition)
    // }, [lastPosition])

    // useEffect(() => {
    //     console.log("Vehicle: ", vehicle)
    //  }, [vehicle])

    const truckIconL = L.icon({
        iconUrl: truckIcon,
        iconRetinaUrl: truckIcon,
        iconSize: [35, 35],
        className: "green"
    });

    //fit all vehicles to map bounds
    const bounds = L.latLngBounds(lastPosition && [[lastPosition.map(x => [x.latitude, x.longitude])]]);

    useEffect(() => {
        //warehouse && services.getVehicles(JSON.stringify(warehouse.map(d => d.oid)))
        //warehouse && services.getVehicles(JSON.stringify([].concat(warehouse.map(d => d.oid))))

        vehicle && services.getLastposition(JSON.stringify(vehicle.sessionVehicle.map(d => d.userOid))).then(res => setLastposition(res.entity))
        setInterval(() => {
            vehicle && services.getLastposition(JSON.stringify(vehicle.sessionVehicle.map(d => d.userOid))).then(res => setLastposition(res.entity))
        }, 15000);
    }, [vehicle])



    const iconMarkup = (color) => {
        return renderToStaticMarkup(
            <i className="fa fa-truck" style={{ "color": color, "fontSize": "35px", "margin": "0" }} />
        );
    }
    const customMarkerIcon = (clr) => {
        return L.divIcon({
            html: iconMarkup(clr)
        });
    }

    const center = [51.505, -0.09]

    return (
        <>
        <div className="headerSpace"></div>
        {
            lastPosition && <MapContainer bounds={bounds} zoom={10} scrollWheelZoom={false} >
                <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />


                {
                    lastPosition && lastPosition.map((pos, i) => (

                        // <Marker position={[pos.latitude, pos.longitude]} icon={truckIconL}>
                        //     <Popup>
                        //         {pos.latitude}
                        //     </Popup>
                        // </Marker>

                        <Marker
                            data="customdata"
                            position={[pos.latitude, pos.longitude]}
                            icon={customMarkerIcon(vehicle.sessionVehicle.find(dt => dt.userOid == pos.userOid).color)}
                            onClick={e => {
                                console.log(e.target);
                            }}
                        >
                            <Popup>
                                {vehicle.sessionVehicle.find(dt => dt.userOid == pos.userOid).username}
                            </Popup>
                        </Marker>

                    ))}
            </MapContainer >}
        </>

    )
}

export default Map
