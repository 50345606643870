import { Cookies } from 'react-cookie';
import { getUserInfo, post, remove, get, put } from './Ajax';


export function Services() {
    // const clientId = "c2136144fa40488f99ea9110341d5b93";
    var that = this;

    this.login = (username, password, grecaptcha) => {
     
        var urlencoded = new URLSearchParams();
        urlencoded.append('username', username);
        urlencoded.append('password', password);
        urlencoded.append('g-recaptcha-response', grecaptcha);


        return new Promise(async (resolve, reject) => {
            return await post({
                url: `authorize`,
                body: urlencoded,
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                skip: true,
                login: true
            }).then(res => resolve(res))
                .catch(that.handleError.bind(null, reject));
        })
    }


    this.logout = () => {
        return new Promise(async (resolve, reject) => {
            return await remove({
                url: `authorize`,
                login: true
            }).then(e => resolve(e))
                .catch(that.handleError.bind(null, reject));
        })
    }


    this.getWarehouse = () => { 
        // var urlencoded = new URLSearchParams();
        // urlencoded.append('username', username);
        // urlencoded.append('password', password);
        // urlencoded.append('g-recaptcha-response', grecaptcha);


        return new Promise(async (resolve, reject) => {
            return await get({
                url: `rest/routing/tracker/warehouse`,
                //body: urlencoded,
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                skip: true
                //login: true
            }).then(res => resolve(res))
                .catch(that.handleError.bind(null, reject));
        })
    }



    this.getVehicles = (arr) => { 
        // var urlencoded = new URLSearchParams();
        // urlencoded.append('username', username);
        // urlencoded.append('password', password);
        // urlencoded.append('g-recaptcha-response', grecaptcha);


        return new Promise(async (resolve, reject) => {
            return await get({
                url: `rest/routing/tracker/session/summary/all?&warehouseOids=${arr}&date=${new Date().getTime()}`,
                //body: urlencoded,
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                skip: true
                //login: true
            }).then(res => resolve(res))
                .catch(that.handleError.bind(null, reject));
        })
    }

    
    

    this.getLastposition = (arr) => { 
        // var urlencoded = new URLSearchParams();
        // urlencoded.append('username', username);
        // urlencoded.append('password', password);
        // urlencoded.append('g-recaptcha-response', grecaptcha);


        return new Promise(async (resolve, reject) => {
            return await get({
                url: `rest/routing/tracker/firebase/last-position-by-user?userOids=${arr}`,
                //body: urlencoded,
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                skip: true
                //login: true
            }).then(res => resolve(res))
                .catch(that.handleError.bind(null, reject));
        })
    }

    
    

    this.handleError = function (reject, response) {
        if (response.message === "Failed to fetch") {
            return reject({ message: "server_is_down" });
        }
        var entity = response.entity;
        if (response.status && response.status === 401) {
            var state = {
                message: entity.message,
                redirect: window.location.pathname,
            };
            // props.push({ pathname: "/login", state: state });
            return Promise.resolve(response);
        }

        return reject({ message: entity.message, code: entity.code });
    };

}