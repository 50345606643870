import React, { useState, useEffect } from 'react';
import './App.css';
import Header from './screen/Header'
import Body from './screen/Body'
import Login from './screen/Login'
import { Auth } from "../src/api/Auth";

function App() {
  const [bodyRedirect, setBodyRedirect] = useState("table");
  var auth = new Auth();


  return (
    <div className="App">
      { //access token checking
        auth.isAuthenticated() ?
          <>
            <Header setBodyRedirect={setBodyRedirect} />
            <Body redirect={bodyRedirect} />
          </>
          :
          <Login />
      }


    </div>
  );
}

export default App